/* eslint-disable */
export const createExecution = /* GraphQL */ `
  mutation CreateExecution(
    $input: CreateExecutionInput!
  ) {
    createExecution(input: $input) {
      partitionKey
      sortKey
      username
      triggerTime
      initialPayloadTime
    }
  }
`;

export const triggerExecution = /* GraphQL */ `
  mutation TriggerExecution(
    $input: TriggerExecutionInput!
  ) {
    triggerExecution(input: $input) {
      statusCode
      body
    }
  }
`;

export const createRecord = /* GraphQL */ `
  mutation CreateRecord(
    $input: CreateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    createRecord(input: $input, condition: $condition) {
      id
      propertyId
      assetId
      modelId
      name
      operator
      type
      # users {
      #   id
      #   name
      #   email
      #   sms
      #   createdAt
      #   updatedAt
      # }
      # aggreation {
      #   type
      #   period
      #   unit
      #   id
      #   createdAt
      #   updatedAt
      # }
      createdAt
      updatedAt
    }
  }
`;
export const updateRecord = /* GraphQL */ `
  mutation UpdateRecord(
    $input: UpdateRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    updateRecord(input: $input, condition: $condition) {
      id
      propertyId
      assetId
      modelId
      name
      operator
      type
      # users {
      #   id
      #   name
      #   email
      #   sms
      #   createdAt
      #   updatedAt
      # }
      # aggreation {
      #   type
      #   period
      #   unit
      #   id
      #   createdAt
      #   updatedAt
      # }
      createdAt
      updatedAt
    }
  }
`;
export const deleteRecord = /* GraphQL */ `
  mutation DeleteRecord(
    $input: DeleteRecordInput!
    $condition: ModelRecordConditionInput
  ) {
    deleteRecord(input: $input, condition: $condition) {
      id
      propertyId
      assetId
      modelId
      name
      operator
      type
      # users {
      #   id
      #   name
      #   email
      #   sms
      #   createdAt
      #   updatedAt
      # }
      # aggreation {
      #   type
      #   period
      #   unit
      #   id
      #   createdAt
      #   updatedAt
      # }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      name
      email
      sms
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      name
      email
      sms
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      name
      email
      sms
      createdAt
      updatedAt
    }
  }
`;
export const createAggreation = /* GraphQL */ `
  mutation CreateAggreation(
    $input: CreateAggreationInput!
    $condition: ModelAggreationConditionInput
  ) {
    createAggreation(input: $input, condition: $condition) {
      type
      period
      unit
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateAggreation = /* GraphQL */ `
  mutation UpdateAggreation(
    $input: UpdateAggreationInput!
    $condition: ModelAggreationConditionInput
  ) {
    updateAggreation(input: $input, condition: $condition) {
      type
      period
      unit
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteAggreation = /* GraphQL */ `
  mutation DeleteAggreation(
    $input: DeleteAggreationInput!
    $condition: ModelAggreationConditionInput
  ) {
    deleteAggreation(input: $input, condition: $condition) {
      type
      period
      unit
      id
      createdAt
      updatedAt
    }
  }
`;
export const createNote = /* GraphQL */ `
  mutation CreateNote(
    $input: CreateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    createNote(input: $input, condition: $condition) {
      id
      text
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateNote = /* GraphQL */ `
  mutation UpdateNote(
    $input: UpdateNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    updateNote(input: $input, condition: $condition) {
      id
      text
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteNote = /* GraphQL */ `
  mutation DeleteNote(
    $input: DeleteNoteInput!
    $condition: ModelNoteConditionInput
  ) {
    deleteNote(input: $input, condition: $condition) {
      id
      text
      createdAt
      updatedAt
      owner
    }
  }
`;
