import React, { useEffect, useCallback, useState } from 'react';
import { API, GraphQLResult } from '@aws-amplify/api';
import { Button, Col, Row } from 'react-bootstrap';
import { GRAPHQL_AUTH_MODE } from '@aws-amplify/auth';
import { IUserResponse, getCurrentUser } from '../utils/auth';
import { getRecord, listRecords } from '../graphql/queries';
import {
  ListRecordsQuery, Record, GetRecordQuery, TriggerExecutionInput,
  CreateExecutionInput, CreateExecutionMutation, TriggerExecutionMutation
} from '../API';
import {
  createExecution, triggerExecution
} from '../graphql/mutations';

function EnviroSenseTest(): JSX.Element {
  const [records, setRecords] = useState<Record[]>([]);
  const [user, setUser] = useState<IUserResponse | null>();
  const [recordDetail, setRecordDetail] = useState<Record | null>();
  const [executingStatus, setExecutingStatus] = useState<string | null>();

  useEffect(() => {
    try {
      getCurrentUser().then((u) => {
        setUser(u);
      });
    } catch (err: unknown) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, []);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    if (recordDetail == null) return;
    switch (e.target.name) {
      case 'siteName':
        recordDetail.templateParameter.siteName = e.target.value;
        break;
      case 'deviceName':
        recordDetail.templateParameter.deviceName = e.target.value;
        break;
      case 'laeq':
        recordDetail.templateParameter.laeq = +e.target.value;
        break;
      case 'laMax':
        recordDetail.templateParameter.laMax = +e.target.value;
        break;
      case 'pm2_5mc':
        recordDetail.templateParameter.pm2_5mc = +e.target.value;
        break;
      case 'pm10mc':
        recordDetail.templateParameter.pm10mc = +e.target.value;
        break;
      default:
    }
    setRecordDetail(recordDetail);
  };

  const fetchRecordDetail = useCallback(async (partitionKey: string) => {
    try {
      const where = {
        partitionKey,
        sortKey: 'Configuration'
      };
      const result: GraphQLResult<GetRecordQuery> = await API.graphql({
        query: getRecord,
        variables: where,
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      }) as GraphQLResult<GetRecordQuery>;
      setRecords([]);
      setRecordDetail(result.data?.getRecord);
    } catch (err: unknown) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [setRecordDetail, setRecords]);

  const fetchRecords = useCallback(async (searchString: string) => {
    try {
      const where = {
        and: [
          { partitionKey: { contains: 'TestScenario#' } },
          { partitionKey: { contains: 'EnviroSense' } },
          { partitionKey: { contains: searchString } },
          { sortKey: { eq: 'Configuration' } }
        ]
      };
      const result: GraphQLResult<ListRecordsQuery> = await API.graphql({
        query: listRecords,
        variables: { filter: where, limit: 10000 },
        authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
      }) as GraphQLResult<ListRecordsQuery>;
      setRecords(result.data?.listRecords?.items ?? []);
      setRecordDetail(null);
    } catch (err: unknown) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }, [setRecords]);

  const handleCreateExecution = useCallback(async (record: Record, username: string) => {
    setExecutingStatus('Sending test data...');
    const input: CreateExecutionInput = {
      partitionKey: record.partitionKey,
      sortKey: `Execution#${username}#${Date.now()}`,
      username: `${username}`,
      triggerTime: Date.now(),
      initialPayloadTime: Date.now(),
      templateParameter: record.templateParameter
    };
    const triggerInput: TriggerExecutionInput = {
      partitionKey: record.partitionKey,
      sortKey: input.sortKey
    };
    await API.graphql<CreateExecutionMutation>({
      query: createExecution,
      variables: {
        input
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    });
    await API.graphql<TriggerExecutionMutation>({
      query: triggerExecution,
      variables: {
        input: triggerInput
      },
      authMode: GRAPHQL_AUTH_MODE.AMAZON_COGNITO_USER_POOLS
    });
    setExecutingStatus('Test data sent');
    setTimeout(() => {
      setExecutingStatus('');
    }, 2000);
  }, []);

  return (
    <>
      <h1>EnviroSense Test</h1>

      { (recordDetail != null) ? (
        <div>
          <Row>
            <Col>
              <h2>{ recordDetail.label}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              { recordDetail.description.replaceAll('\r\n', '<br /><br />')}
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Input Parameters</h3>
            </Col>
          </Row>
          <Row>
            <Col>
              Site Name
            </Col>
            <Col>
              <input name="siteName" type="text" defaultValue={recordDetail.templateParameter.siteName} onChange={handleChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              Device Name
            </Col>
            <Col>
              <input name="deviceName" type="text" defaultValue={recordDetail.templateParameter.deviceName} onChange={handleChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              laeq
            </Col>
            <Col>
              <input name="laeq" type="number" defaultValue={recordDetail.templateParameter.laeq} onChange={handleChange} />
            </Col>
          </Row>
          <Row>
            <Col>
              laMax
            </Col>
            <Col>
              <input name="laMax" type="number" defaultValue={recordDetail.templateParameter.laMax} onChange={handleChange} />
            </Col>
          </Row>
          { (recordDetail.templateParameter.pm2_5mc != null) ? (
            <Row>
              <Col>
                pm 2.5
              </Col>
              <Col>
                <input name="pm2_5mc" type="number" defaultValue={recordDetail.templateParameter.pm2_5mc} onChange={handleChange} />
              </Col>
            </Row>
          ) : ''}
          { (recordDetail.templateParameter.pm10mc != null) ? (
            <Row>
              <Col>
                pm 10
              </Col>
              <Col>
                <input name="pm10mc" type="number" defaultValue={recordDetail.templateParameter.pm10mc} onChange={handleChange} />
              </Col>
            </Row>
          ) : ''}
          <Row>
            <Col>
              <Button
                variant="primary"
                onClick={() => handleCreateExecution(recordDetail, user?.attributes.email ?? '')}
              >
                Execute
              </Button>
              &nbsp;
              <Button
                variant="secondary"
                onClick={() => fetchRecords('')}
              >
                Close
              </Button>
            </Col>
            <Col>
              {executingStatus}
            </Col>
          </Row>
        </div>
      ) : (
        <Row>
          <Col>
            <Button variant="secondary" onClick={() => fetchRecords('')}>Fetch List</Button>
          </Col>
        </Row>
      )}

      { records.map((record) => (
        <Row key={record.partitionKey + record.sortKey}>
          <Col>
            { record.label}
          </Col>
          <Col>
            <Button
              variant="secondary"
              onClick={() => fetchRecordDetail(record.partitionKey)}
            >
              View Detail
            </Button>
          </Col>
        </Row>
      ))}
      <Row />
    </>
  );
}

export default EnviroSenseTest;
