import './App.css';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import EnviroSenseTest from './pages/EnviroSenseTest';
import PlantSmartTest from './pages/PlantSmartTest';

function App(): JSX.Element {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/PlantSmartTest" element={<PlantSmartTest />} />
      <Route path="/EnviroSenseTest" element={<EnviroSenseTest />} />
    </Routes>
  );
}

export default App;
