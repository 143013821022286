import { IEnvironmentConfig } from './IEnvironmentConfig';
import LocalConfig from './LocalConfig';
import TestConfig from './TestConfig';
import DevConfig from './DevConfig';
import ProdConfig from './ProdConfig';

// Simple config export as we are running as a static website we do not have access to environment variables or
// web-pack (as this is still in create-react-app).
// We should revisit as a webpack solution in the future if we eject
export default class ConfigProvider {
  static getConfig(): IEnvironmentConfig {
    const { hostname } = window.location;
    switch (hostname) {
      case 'localhost':
        return LocalConfig;
      case 'ddadmin-dev.dc.np-jhg.cloud':
        return DevConfig;
      case 'ddadmin-test.dc.np-jhg.cloud':
        return TestConfig;
      case 'ddadmin.dc.pr-jhg.cloud':
        return ProdConfig;
      default:
        throw Error('hostname configuration is not recognised.');
    }
  }
}
