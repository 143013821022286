import React from 'react';

export default function Footer(): JSX.Element {
  return (
    <footer className="border-top footer text-muted">
      <div className="container">
        &copy; 2022 - John Holland Group
      </div>
    </footer>
  );
}
