import { IEnvironmentConfig } from './IEnvironmentConfig';

const DevConfig: IEnvironmentConfig = {
  Environment: 'dev',
  AwsConfig: {
    aws_project_region: 'ap-southeast-2',
    aws_appsync_graphqlEndpoint: 'https://u5pinq6yafhhljvikshf7jxr5q.appsync-api.ap-southeast-2.amazonaws.com/graphql',
    aws_appsync_region: 'ap-southeast-2',
    // aws_appsync_authenticationType: '',
    // aws_appsync_apiKey: '',
    // aws_cognito_identity_pool_id: '',
    aws_cognito_region: 'ap-southeast-2',
    aws_user_pools_id: 'ap-southeast-2_17JRskPR9',
    aws_user_pools_web_client_id: '1gcd7gsoi33hhpg90uf70gb1hf',
    oauth: {
      domain: 'ddadmin-dev.auth.ap-southeast-2.amazoncognito.com',
      scope: [
        'phone',
        'email',
        'openid',
        'profile',
        'aws.cognito.signin.user.admin'
      ],
      redirectSignIn: 'https://ddadmin-dev.dc.np-jhg.cloud/',
      redirectSignOut: 'https://ddadmin-dev.dc.np-jhg.cloud/',
      responseType: 'code'
    },
    federationTarget: 'COGNITO_USER_POOLS'
    // aws_cognito_username_attributes: [],
    // aws_cognito_social_providers: [],
    // aws_cognito_signup_attributes: [
    //   'EMAIL'
    // ],
    // aws_cognito_mfa_configuration: 'OPTIONAL',
    // aws_cognito_mfa_types: [
    //   'SMS'
    // ],
    // aws_cognito_password_protection_settings: {
    //   passwordPolicyMinLength: 8,
    //   passwordPolicyCharacters: []
    // },
    // aws_cognito_verification_mechanisms: [
    //   'EMAIL'
    //
  }
};

export default DevConfig;
