import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Navbar from 'react-bootstrap/Navbar';
import { Nav } from 'react-bootstrap';
import { IUserResponse, getCurrentUser } from '../../../utils/auth';
import SignInButton from '../../SignInButton/SignInButton';
import SignOutButton from '../../SignOutButton/SignOutButton';

export default function Header(): JSX.Element {
  const [user, setUser] = useState<IUserResponse | null>();

  const isAuthenticated = (): boolean => user != null;

  useEffect(() => {
    getCurrentUser().then((u) => {
      setUser(u);
    });
  }, []);

  return (
    <header>
      <Navbar variant="light" expand="lg" className="border-bottom box-shadow mb-3 sticky-top">
        <div className="container">
          <Navbar.Toggle type="button" />
          <Navbar.Collapse>
            <Nav className="me-auto">
              <Link to="/" className="nav-link text-dark">SpoilTrac Test</Link>
              <Link to="/PlantSmartTest" className="nav-link text-dark">PlantSmart Test</Link>
              <Link to="/EnviroSenseTest" className="nav-link text-dark">EnviroSense Test</Link>
            </Nav>

            <Navbar.Collapse className="justify-content-end mr-2">
              <Navbar.Text>{isAuthenticated() ? `Logged in as ${user?.attributes.email}` : ''}</Navbar.Text>
            </Navbar.Collapse>
            <Nav>
              {isAuthenticated() ? <SignOutButton /> : <SignInButton />}
            </Nav>
          </Navbar.Collapse>
        </div>
      </Navbar>
    </header>
  );
}
