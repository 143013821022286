import React from 'react';
import Button from 'react-bootstrap/Button';
import { Auth } from 'aws-amplify';

async function handleLogout(): Promise<void> {
  await Auth.signOut();
}

export default function SignOutButton(): JSX.Element {
  return (
    <Button variant="secondary" size="sm" onClick={() => handleLogout()}>Sign out</Button>
  );
}
