import React, { useEffect, useState } from 'react';
import { Hub } from 'aws-amplify';
import SignInButton from '../SignInButton/SignInButton';
import { getCurrentUser, IUserResponse } from '../../utils/auth';

interface IAuthenticatorProps {
  children: JSX.Element;
}

export default function Authenticator({ children }: IAuthenticatorProps): JSX.Element {
  const [user, setUser] = useState<IUserResponse | null>();

  useEffect(() => {
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          // eslint-disable-next-line no-console
          console.log('sign in', event, data);
          // fetchNotes()
          // this.setState({ user: data})
          break;
        case 'signOut':
          // eslint-disable-next-line no-console
          console.log('sign out');
          // this.setState({ user: null })
          break;
        default:
          // eslint-disable-next-line no-console
          console.log(event);
      }
    });
    getCurrentUser().then((u) => setUser(u));
  }, []);

  if (user == null) {
    return (
      <>
        <div>Log in to continue</div>
        <SignInButton />
      </>
    );
  }
  return children;
}
