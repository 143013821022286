import { Auth } from 'aws-amplify';

export interface IUserResponseAttributes {
    email: string,
    name: string,
    sub: string
  }

export interface IUserResponse {
    username: string,
    attributes: IUserResponseAttributes
  }

export async function getCurrentUser(): Promise<IUserResponse | null> {
  try {
    const authUser = await Auth.currentAuthenticatedUser();
    return authUser as IUserResponse;
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log(e);
    return Promise.resolve(null);
  }
}
