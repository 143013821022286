import { Auth } from 'aws-amplify';
import React from 'react';
import Button from 'react-bootstrap/Button';

async function handleLogin(): Promise<void> {
  await Auth.federatedSignIn();
}

export default function SignInButton(): JSX.Element {
  return (
    <Button variant="secondary" size="sm" onClick={() => handleLogin()}>Sign in</Button>
  );
}
