import React from 'react';
import Header from './Header/Header';
import Footer from './Footer/Footer';

interface LayoutProps {
  children: React.ReactNode;
}

export default function Layout({ children }: LayoutProps): JSX.Element {
  return (
    <>
      <Header />
      <div className="App container">
        <main role="main" className="pb-3">
          {children}
        </main>
      </div>
      <Footer />
    </>
  );
}
