/* eslint-disable */
export const getRecord = /* GraphQL */ `
  query GetRecord($partitionKey: String!, $sortKey: String!) {
    getRecord(partitionKey: $partitionKey, sortKey: $sortKey) {
      partitionKey
      sortKey
      label
      testType
      description
      payloadCount
      templateParameter {
        dumpLatitude
        dumpLongitude
        excavatorSerialNo
        loadLatitude
        loadLongitude
        truckSerialNo
        siteName
        deviceName
        laeq
        laMax
        pm2_5mc
        pm10mc
        deviceSerialNo
        analog11Start
        analog11End    
      }
    }
  }
`;
export const listRecords = /* GraphQL */ `
  query ListRecords(
    $filter: ModelRecordFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRecords(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        partitionKey
        sortKey
        testType
        label
      }
      nextToken
    }
  }
`;
